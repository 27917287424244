import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ValidationMessageDirective } from '../../../shared/directives/validation-message.directive';
import { ControlsOf } from '../../../tools/interfaces/form.interfaces';
import { GameSocialLinks } from '../../../tools/interfaces/response.interfaces';

@Component({
  selector: 'app-game-social-links-subform',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ValidationMessageDirective,
    InputTextModule,
  ],
  templateUrl: './game-social-links-subform.component.html',
})
export class GameSocialLinksSubformComponent {
  @Input() socialLinksForm!: FormGroup<ControlsOf<GameSocialLinks>>;
}
