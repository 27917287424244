<form [formGroup]="installationForm">
  @if(filesPreviouslyUploaded === false) {
  <ng-container>
    @if (!externalLinkForm.controls['url'].value) {
    <div
      class="flex justify-content-between align-items-center border-1 border-gray-800 border-round-xl px-3 py-2 mb-5"
    >
      <span class="text-white text-md">Select the game zip folder</span>
      @if (!selectedGameArchiveName) {
      <p-button (onClick)="archiveSelector.click()">Select</p-button>
      } @else {
      <div
        class="text-md p-2 flex justify-content-center align-items-center gap-3"
      >
        <span class="text-white">{{ selectedGameArchiveName }}</span>
        <span
          class="pi pi-trash cursor-pointer hover:text-red-400 transition-duration-200"
          (click)="onRemoveArchive()"
        ></span>
      </div>
      }
    </div>
    } @if (installationForm.controls['archive'].value) {
    <div class="mb-3 relative">
      <div
        class="flex justify-content-between align-items-center border-1 border-gray-800 border-round-xl px-3 py-2 mb-5"
      >
        <span class="text-white text-md">Select Game Launcher</span>
        <p-dropdown
          formControlName="executablePath"
          [options]="gameExeList"
          placeholder="Select Game Executable"
          [loading]="!gameExeList?.length"
          [lazy]="true"
        >
        </p-dropdown>
      </div>
    </div>
    } @else {
    <div>
      @if ( !externalLinkForm.controls['url'].value ) {
      <span class="text-gray-400 flex gap-3 align-items-center mb-5 text-md">
        <hr class="border-none border-top-1 border-gray-700 flex-1" />
        <span>Or</span>
        <hr class="border-none border-top-1 border-gray-700 flex-1" />
      </span>

      }

      <div class="mb-3 relative" [formGroup]="externalLinkForm">
        <label for="game-link" class="block mb-1 text-md text-gray-200">
          Browser game link
        </label>
        <input
          id="game-link"
          type="url"
          placeholder="Add link"
          pInputText
          class="w-full"
          autocomplete="off"
          formControlName="url"
          [appValidationMessage]="externalLinkForm.controls['url']"
          [validationMessages]="{
            required: 'Add a game link or provide game files',
            pattern: 'Invalid format for the Game URL'
          }"
        />
      </div>
    </div>
    }
  </ng-container>
  } @else if(filesPreviouslyUploaded === true) {
  <div class="text-center mt-6">
    <h2 class="text-white text-2xl mb-3">Game files are already hosted</h2>
    <p class="text-white text-md mb-5">
      If you want to make any changes you can reupload them
    </p>
    <p-button
      class="p-button-primary p-button-md"
      (onClick)="archiveSelector.click()"
    >
      Reupload
    </p-button>
  </div>
  }

  <input
    type="file"
    accept=".zip"
    class="hidden"
    #archiveSelector
    (change)="onSelectArchive($event)"
    [multiple]="false"
  />
</form>
