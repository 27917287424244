<div class="max-w-800px m-auto flex mb-5" [ngStyle]="{ 'max-width': '50rem' }">
  <img [src]="icon" alt="" class="w-4rem h-4rem" />
  <div class="ml-3">
    <h2 class="text-2xl line-height-2 m-0">{{ title }}</h2>
    <p-tag [value]="status | convertEnumFromBE" [class]="status"></p-tag>
  </div>

  <div class="ml-auto flex gap-2">
    <ng-container *ngIf="reviewFeedback$ | async as feedbackResponse">
      <p-button
        severity="primary"
        [hidden]="!isRevisionsRequired"
        label="View feedback"
        (onClick)="onOpenFeedback(feedbackResponse.review)"
      ></p-button>
    </ng-container>

    <p-button
      severity="secondary"
      label="Preview Page"
      (onClick)="onOpenGamePreview()"
      [disabled]="isPagePreviewDisabled"
    ></p-button>
  </div>
</div>
