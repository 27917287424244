import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, tap } from 'rxjs';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { NotificationService } from '../services/notification.service';

export const AuthGuard: CanActivateFn = (
  activatedRoute: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {
  const authService: AuthService = inject(AuthService);
  const notificationService: NotificationService = inject(NotificationService);
  const router: Router = inject(Router);

  return authService.isLoggedIn$.pipe(
    tap((loggedIn: boolean) => {
      if (!loggedIn) {
        notificationService.throwInfoNotification("Isn't logged in");
        router.navigateByUrl('/start');
      }
    })
  );
};
