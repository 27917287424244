import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GameData } from '../../../../tools/interfaces/response.interfaces';
import { ButtonModule } from 'primeng/button';
import { AdminService } from '../../../../shared/services/admin/admin.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { Observable } from 'rxjs';
import { GameRequestsService } from '../../../../shared/services/games/game-requests.service';
import {
  GAME_STAGE,
  GAME_STATUS,
} from '../../../../tools/constants/game.constants';

@Component({
  selector: 'app-admin-game-access-tab',
  standalone: true,
  imports: [NgIf, AsyncPipe, ButtonModule],
  templateUrl: './admin-game-access-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminGameAccessTabComponent {
  @Input() gameData!: GameData;

  public gameDownloadLink$!: Observable<{ url: string }>;
  public gameWebLink$!: Observable<{ sourceUrl: string }>;

  constructor(
    private gamesRequestsService: GameRequestsService,
    public adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.initializeGameLinks();
  }

  onDownloadGame(downloadLink: string): void {
    window.location.href = downloadLink;
  }

  private initializeGameLinks(): void {
    const targetStage: GAME_STAGE = this.getTargetStageKey(
      this.gameData.status
    );
    this.gameWebLink$ = this.gamesRequestsService.getGameWebLinkRequest(
      this.gameData.id,
      targetStage
    );
    this.gameDownloadLink$ =
      this.gamesRequestsService.getGameDownloadLinkRequest(this.gameData.id);
  }

  // TODO: refactor to avoid code duplication
  private getTargetStageKey(gameStatus: GAME_STATUS): GAME_STAGE {
    switch (gameStatus) {
      case GAME_STATUS.REVIEW:
      case GAME_STATUS.CHANGES_REQUESTED:
      case GAME_STATUS.REJECTED: {
        return GAME_STAGE.REVIEW;
      }
      case GAME_STATUS.DRAFT: {
        return GAME_STAGE.DRAFT;
      }
      case GAME_STATUS.READY_FOR_PUBLISH:
      case GAME_STATUS.PUBLISHING: {
        return GAME_STAGE.READY_FOR_RELEASE;
      }
      default: {
        return GAME_STAGE.RELEASE;
      }
    }
  }
}
