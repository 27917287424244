import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-revisions-required-game-dialog',
  standalone: true,
  imports: [ButtonModule, InputTextareaModule, FormsModule],
  templateUrl: './revisions-required-game-dialog.component.html',
})
export class RevisionsRequiredGameDialogComponent {
  //TODO: Add validation for feedback
  feedback: string = '';
  requestRevisions = (id: string, feedback: string) => {};

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.config.styleClass = 'bg-gray-1000 w-600px';
    this.config.header = 'Revisions required';
    this.requestRevisions = this.config.data.requestRevisions;
  }

  get gameId(): string {
    return this.config.data.gameId;
  }

  onRequesRevisionsClick(): void {
    this.requestRevisions(this.gameId, this.feedback);
    this.ref.close();
  }

  onCancel(): void {
    this.ref.close();
  }
}
