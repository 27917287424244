import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PASSWORD } from '../../tools/constants/pattern.constants';
import { ValidationMessageDirective } from '../../shared/directives/validation-message.directive';
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthRequestsService } from '../../shared/services/auth/auth-requests.service';
import { NotificationService } from '../../shared/services/notification.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    PasswordModule,
    ButtonModule,
    ValidationMessageDirective,
  ],
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
  token = '';

  resetForm: FormGroup = this.fb.group({
    password: [null, [Validators.required, Validators.pattern(PASSWORD)]],
    passwordRepeat: [null, [Validators.required, Validators.pattern(PASSWORD)]],
  });

  get isButtonDisabled() {
    const password = this.resetForm.get('password')?.value;
    const passwordRepeat = this.resetForm.get('passwordRepeat')?.value;

    return !password || password !== passwordRepeat || this.resetForm.invalid;
  }

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authRequestsService: AuthRequestsService,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParamMap.get('token') || '';
  }

  onSubmit(): void {
    if (this.resetForm.invalid) return;

    const password = this.resetForm.get('password')?.value;

    this.authRequestsService
      .resetPasswordConfirmationRequest(this.token, password)
      .subscribe(() => {
        this.notificationService.throwSuccessNotification(
          'Password successfully changed'
        );
        this.router.navigateByUrl('auth/login');
      });
  }
}
