import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { BodyBackgroundSetterComponent } from '../../shared/components/body-background-setter/body-background-setter.component';
import { DialogService } from 'primeng/dynamicdialog';
import { ReviewFeedbackDialogComponent } from '../../shared/modals/review-feedback-dialog/review-feedback-dialog.component';
import { TagModule } from 'primeng/tag';
import { GAME_STATUS } from '../../tools/constants/game.constants';
import { ConvertEnumFromBEPipe } from '../../shared/pipes/convert-enum-from-be.pipe';
import { GamesService } from '../../shared/services/games/games.service';
import { AsyncPipe, NgIf, NgStyle } from '@angular/common';
import { GameRequestsService } from '../../shared/services/games/game-requests.service';
import { map, switchMap, of, Observable } from 'rxjs';

@Component({
  selector: 'app-review-feedback',
  standalone: true,
  imports: [
    NgStyle,
    NgIf,
    AsyncPipe,
    BodyBackgroundSetterComponent,
    ButtonModule,
    TagModule,
    ConvertEnumFromBEPipe,
  ],
  templateUrl: './review-feedback.component.html',
})
export class ReviewFeedbackComponent {
  GAME_STATUS: typeof GAME_STATUS = GAME_STATUS;

  constructor(
    private dialogService: DialogService,
    private gameService: GamesService,
    private gameRequestsService: GameRequestsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  getGameFeedback(): Observable<{ gameId: string; feedback: string } | null> {
    return this.route.paramMap.pipe(
      map((params) => params.get('game-id')),
      switchMap((id) => {
        if (!id) {
          this.router.navigateByUrl('/app/home');
          return of(null);
        }
        const gameId: number = +id;
        const isUserGame = this.gameService.gamesList.some(
          (game) => game.id === gameId
        );
        const isRevisionsRequired =
          this.gameService.getGameById(gameId)?.status ===
          GAME_STATUS.CHANGES_REQUESTED;

        if (!isUserGame || !isRevisionsRequired) {
          this.router.navigateByUrl('/app/home');
          return of(null);
        }

        return this.gameRequestsService.getReviewFeedbackRequest(gameId).pipe(
          map((feedbackResponse) => ({
            gameId: id,
            feedback: feedbackResponse.review,
          }))
        );
      })
    );
  }

  viewFeedback(review: { gameId: string; feedback: string }): void {
    this.dialogService.open(ReviewFeedbackDialogComponent, {
      data: {
        id: review.gameId,
        feedback: review.feedback,
      },
    });
  }
}
