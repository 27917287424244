import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';

import {
  ContentWrapper,
  GameData,
  GameDataResponse,
} from '../../../tools/interfaces/response.interfaces';

import { GAME_STATUS } from '../../../tools/constants/game.constants';

@Injectable({
  providedIn: 'root',
})
export class AdminRequestsService {
  constructor(private http: HttpClient) {}

  getGamesForReviewRequest(params: HttpParams) {
    return this.http.get<ContentWrapper<GameDataResponse[]>>(
      `${environment.devportalApi}/api/game/all`,
      {
        params,
      }
    );
  }

  approveGameRequest(id: number) {
    return this.http.post<GameData>(
      `${environment.devportalApi}/api/game/status/${id}/review/approve`,
      {}
    );
  }

  rejectGameRequest(id: number) {
    return this.http.post<GameData>(
      `${environment.devportalApi}/api/game/status/${id}/review/reject`,
      {}
    );
  }

  sendFeedbackRequest(id: number, feedback: string) {
    return this.http.post(
      `${environment.devportalApi}/api/game/status/${id}/review/request-changes`,
      {
        review: feedback,
      }
    );
  }

  publishGameRequest(id: number) {
    return this.http.put<GameData>(
      `${environment.devportalApi}/api/game/status/publish/${id}`,
      {}
    );
  }

  unpublishGameRequest(id: number) {
    return this.http.put<GameData>(
      `${environment.devportalApi}/api/game/status/unpublish/${id}`,
      {}
    );
  }

  deleteGameRequest(id: number) {
    return this.http.delete<GameData>(
      `${environment.devportalApi}/api/game/${id}/delete`,
      { body: {} }
    );
  }

  resetGameStatusRequest(id: number, targetStatus: keyof typeof GAME_STATUS) {
    return this.http.put(
      `${environment.devportalApi}/api/game/status/reset/${id}`,
      {
        targetStatus,
      }
    );
  }
}
