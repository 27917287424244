<h3 class="font-medium text-4xl text-white mb-3">Legal documents</h3>
<p class="font-normal text-md text-gray-500 mb-6">
  Links to all necessary documentation
</p>

<dl class="flex flex-column gap-6 mb-8">
  <div>
    <dt class="font-normal text-md text-gray-200 line-height-3 mb-1">
      Terms and conditions
    </dt>
    <dd>
      <a
        class="font-normal underline cursor-pointer text-md text-white line-height-3 m-0 transition-colors transition-duration-200 hover:text-gray-300"
        (click)="adminService.onRedirect(gameData.legal.terms)"
      >
        {{ gameData.legal.terms }}
      </a>
    </dd>
  </div>

  <div>
    <dt class="font-normal text-md text-gray-200 line-height-3 mb-1">
      Privacy Policy
    </dt>
    <dd>
      <a
        class="font-normal underline cursor-pointer text-md text-white line-height-3 m-0 transition-colors transition-duration-200 hover:text-gray-300"
        (click)="adminService.onRedirect(gameData.legal.policy)"
      >
        {{ gameData.legal.policy }}
      </a>
    </dd>
  </div>

  <div>
    <dt class="font-normal text-md text-gray-200 line-height-3 mb-1">
      End User License Agreement (EULA)
    </dt>
    <dd>
      @if (gameData.legal.license) {
      <a
        class="font-normal underline cursor-pointer text-md text-white line-height-3 m-0 transition-colors transition-duration-200 hover:text-gray-300"
        (click)="adminService.onRedirect(gameData.legal.license)"
      >
        {{ gameData.legal.license }}
      </a>
      } @else { No EULA provided }
    </dd>
  </div>
</dl>
