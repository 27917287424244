<div class="flex flex-column justify-content-center align-items-center h-full">
  <form
    [formGroup]="gameProfileForm"
    (ngSubmit)="onSubmit()"
    [ngStyle]="{
      'max-width': '26rem'
    }"
    class="w-full"
  >
    <h3 class="text-xl font-medium text-left mb-6">
      {{ isFirstGame ? 'Add your first game' : 'Add your game' }}
    </h3>

    <div class="mb-5 relative">
      <label
        for="game-title"
        class="block text-lg text-gray-200 text-left mb-1"
      >
        Game title
      </label>
      <input
        id="game-title"
        type="text"
        placeholder="Enter game name"
        pInputText
        class="w-full"
        formControlName="title"
        autocomplete="off"
        [appValidationMessage]="gameProfileForm.get('title')"
        [validationMessages]="{
          required: 'Game title is required',
          pattern: 'Invalid format for title'
        }"
      />
    </div>

    <div class="mb-5">
      <label for="file" class="block text-md text-gray-200 text-left mb-1">
        Game icon
        <span
          class="pi pi-question-circle"
          [pTooltip]="iconTooltipContent"
          tooltipPosition="top"
          [autoHide]="false"
        ></span>

        <ng-template #iconTooltipContent>
          Displayed in the games section on the home page.
          <a
            (click)="redirectToDocs()"
            class="text-purple-300 cursor-pointer hover:text-purple-200"
          >
            Go to Docs
          </a>
        </ng-template>
      </label>

      <app-media-upload
        classNames="h-8rem hover:border-primary"
        (imagesUploaded)="onIconSelect($event)"
        [multiple]="false"
        [imageType]="IMAGE_TYPE.ICON"
      >
        @if(!gameProfileForm.controls['icon'].value) {
        <div
          class="text-gray-300 p-5 border-2 border-dotted border-gray-700 border-round-2xl"
        >
          <span class="pi pi-upload text-2xl"></span>
        </div>
        } @else {
        <div class="relative" appRemoveButton (imageRemoved)="onRemoveIcon()">
          <img
            [src]="gameProfileForm.controls['icon'].value"
            class="w-5rem h-5rem"
            alt="Selected icon"
          />
        </div>
        }
      </app-media-upload>
    </div>
    <p-button
      class="p-fluid block mb-3"
      label="Add game"
      type="submit"
      [disabled]="gameProfileForm.invalid"
    ></p-button>

    <p class="text-gray-400 text-md text-center">
      By signing up, you agree to our
      <a
        class="text-primary font-normal no-underline hover:text-primary-300 cursor-pointer"
        (click)="onOpenPrivacyPolicy($event)"
      >
        Privacy Policy
      </a>
      and
      <a
        class="text-primary font-normal no-underline hover:text-primary-300 cursor-pointer"
        (click)="onOpenLicenseAgreement($event)"
      >
        End User License Agreement (EULA)
      </a>
    </p>
  </form>

  <img
    src="./assets/illustrations/background-lights.png"
    class="absolute bottom-0 left-0 w-full"
    [ngStyle]="{ 'z-index': '-1', }"
    alt=""
  />
</div>
<app-body-background-setter></app-body-background-setter>
