import { Component, Input, NgZone } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChipsModule } from 'primeng/chips';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PanelModule } from 'primeng/panel';
import { TabMenuModule } from 'primeng/tabmenu';
import { MediaUploadComponent } from '../../../shared/components/media-upload/media-upload.component';
import { NgClass, NgStyle } from '@angular/common';
import {
  GAME_CHAINS,
  GAME_GENRES,
  RELEASE_STAGE,
} from '../../../tools/constants/game.constants';
import { ListboxModule } from 'primeng/listbox';
import { CalendarModule } from 'primeng/calendar';
import { ValidationMessageDirective } from '../../../shared/directives/validation-message.directive';
import { Editor, NgxEditorModule, Toolbar } from 'ngx-editor';
import { RemoveButtonDirective } from '../../../shared/directives/remove-button.directive';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { Router, RouterModule } from '@angular/router';
import { IMAGE_TYPE } from '../../../tools/constants/image.constants';
import { NotificationService } from '../../../shared/services/notification.service';

@Component({
  selector: 'app-game-basic-info-subform',
  standalone: true,
  imports: [
    NgStyle,
    NgClass,
    ValidationMessageDirective,
    ListboxModule,
    MediaUploadComponent,
    FormsModule,
    ReactiveFormsModule,
    PanelModule,
    TabMenuModule,
    InputTextModule,
    InputTextareaModule,
    ChipsModule,
    CalendarModule,
    RemoveButtonDirective,
    FormsModule,
    NgxEditorModule,
    DropdownModule,
    TooltipModule,
    RouterModule,
  ],
  templateUrl: './game-basic-info-subform.component.html',
})
export class GameBasicInfoSubformComponent {
  @Input() basicInfoForm!: FormGroup;
  @Input() gameId!: number;

  readonly genres: GAME_GENRES[] = Object.values(GAME_GENRES);
  readonly chains: GAME_CHAINS[] = Object.values(GAME_CHAINS);
  readonly stages: RELEASE_STAGE[] = Object.values(RELEASE_STAGE);
  readonly MIN_RELEASE_DATE: Date = new Date(2000, 0, 1);
  readonly IMAGE_TYPE: typeof IMAGE_TYPE = IMAGE_TYPE;

  editor!: Editor;
  toolbar!: Toolbar;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private notificationService: NotificationService
  ) {
    this.initializeTextEditor();
  }

  get screenshots(): string[] {
    return this.basicInfoForm.controls['imageUris']?.value ?? [];
  }

  get bannerImage(): string {
    return this.basicInfoForm.controls['bannerImageUri']?.value ?? '';
  }
  get backgroundImage(): string {
    return this.basicInfoForm.controls['backgroundImageUri']?.value ?? '';
  }

  onBannerSelect(event: string[]): void {
    const [banner] = event;
    this.basicInfoForm.patchValue({
      bannerImageUri: banner,
    });
  }

  onBackgroundSelect(event: string[]): void {
    const [background] = event;

    this.basicInfoForm.patchValue({
      backgroundImageUri: background,
    });
  }

  onScreenshotsSelected(addedScreenshots: string[]): void {
    const previousScreenshots = this.screenshots;
    const allScreenshots = [...previousScreenshots, ...addedScreenshots];
    const uniqueScreenshots = new Set([
      ...previousScreenshots,
      ...addedScreenshots,
    ]);

    if (allScreenshots.length !== uniqueScreenshots.size) {
      this.notificationService.throwErrorNotification(
        'Duplicate images are not allowed'
      );
    }

    this.basicInfoForm.patchValue({
      imageUris: [...uniqueScreenshots],
    });
  }

  onRemoveBackground(): void {
    this.basicInfoForm.patchValue({
      backgroundImageUri: '',
    });
  }

  onRemoveBanner(): void {
    this.basicInfoForm.patchValue({
      bannerImageUri: '',
    });
  }

  onRemoveScreenshot(index: number): void {
    const patchedScreenshots: string[] = this.screenshots.slice();
    patchedScreenshots.splice(index, 1);

    this.basicInfoForm.patchValue({
      imageUris: patchedScreenshots,
    });
  }

  redirectToDocs(): void {
    this.ngZone.run(() => {
      this.router.navigate(['/app/documentation/gettingstarted'], {
        fragment: 'images',
      });
    });
  }

  private initializeTextEditor(): void {
    this.editor = new Editor();
    this.toolbar = [
      ['bold', 'italic'],
      ['ordered_list', 'bullet_list'],
      [{ heading: ['h2', 'h3', 'h4'] }],
    ];
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
