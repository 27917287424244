<div class="relative">
  <div
    [ngStyle]="{
      background: 'linear-gradient(90deg, #1a2980, #26d0ce)',
      'z-index': '-1'
    }"
    class="w-full h-12rem absolute top-0"
  ></div>

  <div
    class="mx-auto flex flex-column pt-8"
    [ngStyle]="{ 'max-width': '90rem' }"
  >
    @if (gamesService.gamesList.length) {
    <div class="mb-4">
      <h2 class="text-white font-medium text-3xl mb-5 pl-3">
        GAIMIN Games Developer Tool
      </h2>

      <div class="flex flex-wrap gap-3 justify-content-between">
        @for (banner of homeBanners; track $index) {
        <div
          class="bg-gray-950 relative flex-1 cursor-pointer p-3 border-round-md transition-duration-300 hover-scale-05"
          (click)="onRedirect(banner.route)"
        >
          <div class="card-small-text">
            <h3 class="text-md font-medium mt-0 mb-1">
              {{ banner.title }}
            </h3>
            <a class="text-primary text-md font-normal">{{ banner.link }}</a>
          </div>
          <img
            [src]="banner.smallImage"
            alt=""
            class="absolute w-auto absolute"
            [ngStyle]="{ height: '100%', right: '5%', top: '-10%' }"
          />
        </div>
        }
      </div>
    </div>

    <div class="mb-7">
      <h3 class="text-2xl mb-4 pl-3">Games</h3>

      <div class="flex justify-content-center">
        <div class="grid w-full">
          @for (game of gamesService.gamesList; track $index) {
          <div class="col-3">
            <div
              class="bg-gray-900 border-round-xl cursor-pointer transition-duration-300 hover-scale-05 relative overflow-hidden"
              (click)="redirectToGameReview(game.id)"
              [buttonStyles]="{ left: 'auto', right: '5%' }"
              [appRemoveButton]="
                game.status !== GAME_STATUS.UNPUBLISH_REQUESTED
              "
              (imageRemoved)="onRemoveGame(game)"
            >
              <img
                [src]="
                  game?.info?.bannerImageUri ??
                  './assets/games/banner-placeholder.svg'
                "
                alt=""
                class="w-full"
                [ngStyle]="{
                  'min-width': '300px',
                  'aspect-ratio': '16 / 9',
                  'object-fit': 'cover',
                  'object-position': 'top'
                }"
              />
              <div
                class="flex justify-content-between align-items-center py-2 px-3 text-lg"
              >
                <h4>
                  {{ game.info.title }}
                </h4>
                <p-tag
                  [value]="game.status | convertEnumFromBE"
                  [class]="game.status"
                ></p-tag>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
    } @else {
    <div class="mb-8">
      <h2 class="text-white font-medium text-4xl mb-4">
        Getting Started with GAIMIN
      </h2>

      <div class="flex gap-3 justify-content-between">
        @for (banner of homeBanners; track $index) {
        <div
          class="bg-gray-950 p-4 border-round-2xl cursor-pointer transition-duration-300 hover-scale-05 flex-1 text-center"
          (click)="router.navigateByUrl(banner.route)"
        >
          <img [src]="banner.image" alt="" class="border-round-xl" />

          <div class="flex justify-content-between text-center text-left">
            <div>
              <h3 class="text-xl font-medium mb-1 mt-4">{{ banner.title }}</h3>
              <a class="text-primary text-md font-normal">{{ banner.link }}</a>
            </div>
            <span
              class="pi pi-arrow-up-right align-self-end text-xl text-gray-500"
            ></span>
          </div>
        </div>
        }
      </div>
    </div>
    }
  </div>
</div>
