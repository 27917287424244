<img
  class="absolute-centered opacity-30"
  src="./assets/illustrations/new-game.png"
  alt=""
  [ngStyle]="{ 'z-index': '-1' }"
/>

<div
  class="flex flex-column justify-content-center align-items-center h-full"
  *ngIf="getGameFeedback() | async as reviewResponse"
>
  <p-tag
    [value]="GAME_STATUS.CHANGES_REQUESTED | convertEnumFromBE"
    [class]="GAME_STATUS.CHANGES_REQUESTED"
    class="mb-4"
  ></p-tag>
  <h2 class="z-5 text-4xl font-medium line-height-2 text-center mb-3">
    Before publishing you need to change something
  </h2>

  <p class="z-5 mb-6 text-lg font-normal">
    We found couple of things that need to change to reach better result.
  </p>

  <p-button (onClick)="viewFeedback(reviewResponse)" label="View feedback">
  </p-button>
</div>

<app-body-background-setter></app-body-background-setter>
