import { inject } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { CanActivateFn, Router } from '@angular/router';
import { NetworkService } from '../services/network.service';
import { NotificationService } from '../services/notification.service';

export const OnlineGuard: CanActivateFn = (): Observable<boolean> => {
  const networkService: NetworkService = inject(NetworkService);
  const notificationService: NotificationService = inject(NotificationService);
  const router: Router = inject(Router);

  return networkService.isOnline$.pipe(
    tap({
      next: (online: boolean) => {
        if (!online) {
          notificationService.throwErrorNotification(
            'There are some problems with internet connection'
          );
          router.navigateByUrl('/app/home');
        }
      },
    })
  );
};
