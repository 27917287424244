import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SupportService } from '../../services/support.service';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LoaderService } from '../../services/loader.service';
import { DialogModule } from 'primeng/dialog';
import { ValidationMessageDirective } from '../../directives/validation-message.directive';
import {
  EMAIL,
  QUESTION_TEXT,
} from '../../../tools/constants/pattern.constants';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-support-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CheckboxModule,
    RadioButtonModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    DialogModule,
    ValidationMessageDirective,
  ],
  templateUrl: './support-dialog.component.html',
})
export class SupportDialogComponent {
  reasons: string[] = [
    'Issues with game uploads',
    'Requirements for description and media assets',
    'Terms and conditions of platform use',
    'Another',
  ];

  supportForm: FormGroup;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private fb: FormBuilder,
    private supportService: SupportService,
    private notificationService: NotificationService,
    public loaderService: LoaderService
  ) {
    this.supportForm = this.fb.group({
      subject: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(EMAIL)]],
      description: [
        '',
        [Validators.required, Validators.pattern(QUESTION_TEXT)],
      ],
    });

    this.config.contentStyle = {
      'background-color': '#18181E',
    };
    this.config.header = 'Support';
  }

  onClose(): void {
    this.ref.close();
  }

  onSubmit(): void {
    if (this.supportForm.invalid) return;

    this.supportService.sendSupportRequest(this.supportForm.value).subscribe({
      next: () => {
        this.notificationService.throwSuccessNotification(
          'Thank you for your request! We will contact you shortly.'
        );
        this.onClose();
      },
      error: () => {
        this.notificationService.throwErrorNotification(
          'Support request could not be sent.'
        );
        this.onClose();
      },
    });
  }
}
