import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { tap } from 'rxjs';
import { environment } from '../environments/environment';
import { Response } from '../app/tools/interfaces/response.interfaces';
import { NotificationService } from '../app/shared/services/notification.service';

export const errorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  const notificationService: NotificationService = inject(NotificationService);
  const errorDescriptionBlacklist: string[] = [
    'Game files are not uploaded yet',
    'This operation is only supported for downloadable games',
    'This operation is only supported for web games',
    'Review feedback not found',
    'No game info in the given stage found',
  ];

  return next(req).pipe(
    tap((event) => {
      if (!(event instanceof HttpResponse)) return;

      if (
        !event.url?.startsWith(environment.devportalApi) &&
        !event.url?.startsWith(environment.gaiminApi)
      )
        return;

      const responseBody = event.body as Response<unknown>;

      // TODO: come up with blacklist
      if (
        responseBody.error &&
        !errorDescriptionBlacklist.includes(responseBody.error.description) &&
        !req.url.includes('/api/user/me')
      ) {
        notificationService.throwErrorNotification(
          responseBody.error.description
        );
      }
    })
  );
};
