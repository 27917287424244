import { AsyncPipe, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { ActivatedRoute, Router } from '@angular/router';
import { TagModule } from 'primeng/tag';
import { DialogService } from 'primeng/dynamicdialog';
import { RejectGameComponentDialog } from '../../../shared/modals/reject-game-dialog/reject-game-dialog.component';
import { RevisionsRequiredGameDialogComponent } from '../../../shared/modals/revisions-required-game-dialog/revisions-required-game-dialog.component';
import { AdminService } from '../../../shared/services/admin/admin.service';
import {
  GameData,
  GameDataResponse,
} from '../../../tools/interfaces/response.interfaces';
import { GAME_STATUS } from '../../../tools/constants/game.constants';
import { ConvertEnumFromBEPipe } from '../../../shared/pipes/convert-enum-from-be.pipe';
import { map, Observable, of, switchMap } from 'rxjs';
import { AdminGameDetailsTabComponent } from './admin-game-details-tab/admin-game-details-tab.component';
import { AdminGameLegalTabComponent } from './admin-game-legal-tab/admin-game-legal-tab.component';
import { AdminGameLinksTabComponent } from './admin-game-links-tab/admin-game-links-tab.component';
import { AdminGameAccessTabComponent } from './admin-game-access-tab/admin-game-access-tab.component';
import { AdminRequestsService } from '../../../shared/services/admin/admin-requests.service';
import { LoaderService } from '../../../shared/services/loader.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { GamesService } from '../../../shared/services/games/games.service';

@Component({
  selector: 'app-admin-game',
  standalone: true,
  imports: [
    NgStyle,
    ButtonModule,
    TabViewModule,
    PanelModule,
    TagModule,
    ConvertEnumFromBEPipe,
    NgIf,
    AsyncPipe,
    AdminGameDetailsTabComponent,
    AdminGameLegalTabComponent,
    AdminGameAccessTabComponent,
    AdminGameLinksTabComponent,
  ],
  templateUrl: './admin-game.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AdminGameComponent implements OnInit {
  public readonly GAME_STATUS: typeof GAME_STATUS = GAME_STATUS;
  gameData$!: Observable<GameData | null>;

  constructor(
    public loaderService: LoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private adminService: AdminService,
    private adminRequestsService: AdminRequestsService,
    private notificationService: NotificationService,
    private gamesService: GamesService
  ) {}

  ngOnInit(): void {
    this.gameData$ = this.loadGameData();
  }

  // TODO: move from parsing method to parsing data
  onOpenRejectModal(id: number): void {
    this.dialogService.open(RejectGameComponentDialog, {
      data: {
        rejectGame: this.onRejectGame.bind(this),
        gameId: id,
      },
    });
  }

  // TODO: rewrite using onClose event
  onOpenReviewRequiredModal(id: number): void {
    const a = this.dialogService.open(RevisionsRequiredGameDialogComponent, {
      data: {
        requestRevisions: this.onRequestRevisions.bind(this),
        gameId: id,
      },
    });

    a.onClose.subscribe();
  }

  private onRejectGame(id: number): void {
    this.adminRequestsService.rejectGameRequest(id).subscribe({
      next: () => (this.gameData$ = this.getGameDataById(id)),
    });
  }

  private onRequestRevisions(id: number, feedback: string): void {
    this.adminRequestsService.sendFeedbackRequest(id, feedback).subscribe({
      next: () => (this.gameData$ = this.getGameDataById(id)),
    });
  }

  onApproveGame(id: number): void {
    this.adminRequestsService.approveGameRequest(id).subscribe({
      next: () => (this.gameData$ = this.getGameDataById(id)),
    });
  }

  onPublishGame(id: number): void {
    this.adminRequestsService.publishGameRequest(id).subscribe({
      next: () => (this.gameData$ = this.getGameDataById(id)),
    });
  }

  onUnpublishGame(id: number): void {
    this.adminRequestsService.unpublishGameRequest(id).subscribe({
      next: () => (this.gameData$ = this.getGameDataById(id)),
    });
  }

  onDeleteGame(id: number): void {
    this.adminRequestsService.deleteGameRequest(id).subscribe({
      next: () => {
        this.router.navigateByUrl('/admin/gameslist');

        this.notificationService.throwSuccessNotification(
          'The game has been successfully deleted'
        );
      },
    });
  }

  onResetGameStatus(id: number, targetStatus: GAME_STATUS) {
    const targetStatusKey = this.gamesService.getEnumKey(
      GAME_STATUS,
      targetStatus
    );
    this.adminRequestsService
      .resetGameStatusRequest(id, targetStatusKey)
      .subscribe({
        next: () => (this.gameData$ = this.getGameDataById(id)),
      });
  }

  private getGameId(): Observable<number | null> {
    return this.route.paramMap.pipe(
      map((params) => Number(params.get('game-id')))
    );
  }

  private getGameDataById(id: number): Observable<GameData> {
    return this.adminService.getGamesForReview([]).pipe(
      map((games) => {
        const gameResponseData = games.content.find((game) => game.id === id);
        const parsedGameData: GameData =
          this.gamesService.parseGameDataResponse(
            gameResponseData as GameDataResponse
          );
        return parsedGameData;
      })
    );
  }

  private loadGameData(): Observable<GameData | null> {
    return this.getGameId().pipe(
      switchMap((id) => {
        if (id == null) return of(null);
        return this.getGameDataById(id);
      })
    );
  }
}
