import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  ContentWrapper,
  GameDataResponse,
} from '../../../tools/interfaces/response.interfaces';
import { GAME_STAGE } from '../../../tools/constants/game.constants';

@Injectable({
  providedIn: 'root',
})
export class GameRequestsService {
  constructor(private http: HttpClient) {}

  getGameDownloadLinkRequest(gameId: number): Observable<{ url: string }> {
    return this.http.get<{ url: string }>(
      `${environment.devportalApi}/api/game/${gameId}/download-link`
    );
  }

  getGameWebLinkRequest(
    gameId: number,
    stage: GAME_STAGE
  ): Observable<{ sourceUrl: string }> {
    return this.http.get<{ sourceUrl: string }>(
      `${environment.devportalApi}/api/game/${gameId}/web?stage=${stage}`
    );
  }

  getReviewFeedbackRequest(id: number): Observable<{ review: string }> {
    return this.http.get<{ review: string }>(
      `${environment.devportalApi}/api/game/review/feedback/${id}`
    );
  }

  getUsersGamesRequest(
    params: HttpParams
  ): Observable<ContentWrapper<GameDataResponse[]>> {
    return this.http.get<ContentWrapper<GameDataResponse[]>>(
      `${environment.devportalApi}/api/game/my`,
      {
        params,
      }
    );
  }

  getGameByIdRequest(id: number): Observable<GameDataResponse> {
    return this.http.get<GameDataResponse>(
      `${environment.devportalApi}/api/game/${id}`
    );
  }

  getGameManifest(id: number, stage: GAME_STAGE) {
    return this.http.get(
      `${environment.devportalApi}/api/game/${id}/manifest?stage=${stage}`
    );
  }

  uploadGameImagesRequest(formData: FormData): Observable<string[]> {
    return this.http.post<string[]>(
      `${environment.devportalApi}/api/storage/public/images`,
      formData
    );
  }

  createNewGameRequest(
    title: string,
    iconUrl: string
  ): Observable<GameDataResponse> {
    return this.http.post<GameDataResponse>(
      `${environment.devportalApi}/api/game/create`,
      { title, iconUrl }
    );
  }

  saveGameDraftRequest(gameId: number, draftRequestBody: unknown) {
    return this.http.patch(
      `${environment.devportalApi}/api/game/${gameId}/update`,
      draftRequestBody
    );
  }

  submitGameForReviewRequest(gameId: number) {
    return this.http.post(
      `${environment.devportalApi}/api/game/status/${gameId}/review/send`,
      {}
    );
  }
}
