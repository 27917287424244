import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-reject-game',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './reject-game-dialog.component.html',
})
export class RejectGameComponentDialog {
  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.config.styleClass = 'bg-gray-1000 w-600px';
    this.config.header = 'Reject game';
  }

  get rejectGameMethod(): (id: string) => void {
    return this.config.data.rejectGame;
  }

  get gameId(): string {
    return this.config.data.gameId;
  }

  onGameReject(): void {
    this.rejectGameMethod(this.gameId);
    this.ref.close();
  }

  onCancel(): void {
    this.ref.close();
  }
}
