import { Component, NgZone } from '@angular/core';
import {
  FormGroup,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { GamesService } from '../../shared/services/games/games.service';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { BodyBackgroundSetterComponent } from '../../shared/components/body-background-setter/body-background-setter.component';
import { InputTextModule } from 'primeng/inputtext';
import { MediaUploadComponent } from '../../shared/components/media-upload/media-upload.component';
import { NgStyle } from '@angular/common';
import {
  GameData,
  GameDataResponse,
} from '../../tools/interfaces/response.interfaces';
import { ValidationMessageDirective } from '../../shared/directives/validation-message.directive';
import { DialogService } from 'primeng/dynamicdialog';
import { LicenseAgreementDialogComponent } from '../../shared/modals/license-agreement-dialog/license-agreement-dialog.component';
import { PrivacyPolicyDialogComponent } from '../../shared/modals/privacy-policy-dialog/privacy-policy-dialog.component';
import { RemoveButtonDirective } from '../../shared/directives/remove-button.directive';
import { TooltipModule } from 'primeng/tooltip';
import { IMAGE_TYPE } from '../../tools/constants/image.constants';
import { TEXT } from '../../tools/constants/pattern.constants';

@Component({
  selector: 'app-new-game',
  templateUrl: './new-game.component.html',
  standalone: true,
  imports: [
    NgStyle,
    MediaUploadComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    BodyBackgroundSetterComponent,
    InputTextModule,
    ValidationMessageDirective,
    RemoveButtonDirective,
    TooltipModule,
  ],
})
export class NewGameComponent {
  isFirstGame: boolean;
  gameProfileForm: FormGroup = this.fb.group({
    title: ['', [Validators.required, Validators.pattern(TEXT)]],
    icon: ['', [Validators.required]],
  });

  IMAGE_TYPE = IMAGE_TYPE;

  constructor(
    private fb: NonNullableFormBuilder,
    private gamesService: GamesService,
    private router: Router,
    private dialogService: DialogService,
    private ngZone: NgZone
  ) {
    this.isFirstGame = this.gamesService.gamesList.length === 0;
  }

  onIconSelect(event: string[]): void {
    const [icon] = event;

    this.gameProfileForm.patchValue({ icon });
  }

  onRemoveIcon(): void {
    this.gameProfileForm.patchValue({ iconUrl: '' });
  }

  onSubmit(): void {
    if (this.gameProfileForm.invalid) {
      console.error('Form is not valid');
      return;
    }
    const { icon, title } = this.gameProfileForm.value;
    this.addGame(title, icon);
  }

  private addGame(gameTitle: string, gameIcon: string): void {
    this.gamesService.addNewGame(gameTitle, gameIcon).subscribe({
      next: (addedGameResponse: GameDataResponse) => {
        this.router.navigateByUrl(`/app/game-added/${addedGameResponse.id}`);
      },
    });
  }

  onOpenPrivacyPolicy(event: MouseEvent): void {
    event.preventDefault();
    this.dialogService.open(PrivacyPolicyDialogComponent, {});
  }

  onOpenLicenseAgreement(event: MouseEvent): void {
    event.preventDefault();
    this.dialogService.open(LicenseAgreementDialogComponent, {});
  }

  redirectToDocs(): void {
    this.ngZone.run(() => {
      this.router.navigate(['/app/documentation/gettingstarted'], {
        fragment: 'images',
      });
    });
  }
}
