import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { NOTIFICATION } from '../../tools/constants/notification.constants';

// TODO: use where needed
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private messageService: MessageService) {}

  throwErrorNotification(message: string): void {
    this.messageService.add({
      severity: NOTIFICATION.ERROR,
      detail: message,
    });
  }

  throwSuccessNotification(message: string): void {
    this.messageService.add({
      severity: NOTIFICATION.SUCCESS,
      detail: message,
    });
  }

  throwInfoNotification(message: string): void {
    this.messageService.add({
      severity: NOTIFICATION.INFO,
      detail: message,
    });
  }

  throwWarningNotification(message: string): void {
    this.messageService.add({
      severity: NOTIFICATION.WARNING,
      detail: message,
    });
  }
}
