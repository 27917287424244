import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import {
  GameData,
  GameDataResponse,
} from '../../../tools/interfaces/response.interfaces';
import { GameRequestsService } from './game-requests.service';
import {
  GAME_CHAINS,
  GAME_GENRES,
  GAME_STATUS,
  RELEASE_STAGE,
} from '../../../tools/constants/game.constants';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GamesService {
  private games: GameData[] = [];

  get gamesList(): GameData[] {
    return this.games.slice();
  }

  set gamesList(gameData: GameData[]) {
    this.games = gameData;
  }

  getGameById(id: number): GameData | null {
    return this.games.find((game) => game.id === id) || null;
  }

  getUsersGames(): Observable<GameDataResponse[]> {
    // TODO: use actual backend pagination
    const params: HttpParams = new HttpParams().append('size', 1_000_000);
    return this.gameRequestsService.getUsersGamesRequest(params).pipe(
      map((userGamesResponse) => {
        this.gamesList = userGamesResponse.content.map((game) =>
          this.parseGameDataResponse(game)
        );
        return userGamesResponse.content;
      })
    );
  }

  constructor(private gameRequestsService: GameRequestsService) {}

  addNewGame(title: string, icon: string) {
    return this.gameRequestsService.createNewGameRequest(title, icon).pipe(
      tap((gameResponse) => {
        const parsedGameData: GameData =
          this.parseGameDataResponse(gameResponse);
        this.gamesList = [...this.games, parsedGameData];
      })
    );
  }

  uploadGameImages(images: File[] | Uint8Array[]): Observable<string[]> {
    const formData = new FormData();

    images.forEach((image) => {
      if (image instanceof File) {
        formData.append('files', image);
      } else if (image instanceof Uint8Array) {
        formData.append(
          'files',
          new Blob([image], {
            type: 'image/png',
          })
        );
      }
    });

    return this.gameRequestsService.uploadGameImagesRequest(formData);
  }

  parseGameDataResponse(gameDataResponse: GameDataResponse): GameData {
    const { id, developer, status } = gameDataResponse;
    const statusValue: GAME_STATUS = this.getEnumValue(GAME_STATUS, status);

    const targetStage = this.getTargetStage(statusValue);
    const {
      type,
      title,
      stage,
      imageUrls,
      iconUrl,
      shortDescription,
      fullDescription,
      genres,
      chains,
      backgroundImageUrl,
      bannerImageUrl,
      termsAndConditions,
      privacyPolicy,
      eula,
      websiteUrl,
      youtubeUrl,
      twitterUrl,
      discordUrl,
      telegramUrl,
      instagramUrl,
      supportEmail,
      supportWebsiteUrl,
      release,
    } = gameDataResponse.info[targetStage] ?? {};
    const { date, stage: releaseStage } = release ?? {};
    const genresValue: GAME_GENRES[] =
      genres?.map((genre) => {
        return this.getEnumValue(GAME_GENRES, genre);
      }) || [];
    const chainsValue: GAME_CHAINS[] =
      chains?.map((chain) => {
        return this.getEnumValue(GAME_CHAINS, chain);
      }) || [];
    const releaseStageValue = releaseStage
      ? this.getEnumValue(RELEASE_STAGE, releaseStage)
      : undefined;

    return {
      id,
      status: statusValue,
      developer,
      type,
      info: {
        title: title ?? '',
        imageUris: imageUrls,
        iconUri: iconUrl ?? '',
        shortDescription,
        fullDescription,
        genres: genresValue,
        chains: chainsValue,
        backgroundImageUri: backgroundImageUrl,
        bannerImageUri: bannerImageUrl,
        releaseDate: date ? new Date(date) : null,
        releaseStage: releaseStageValue,
      },
      legal: {
        terms: termsAndConditions,
        license: eula,
        policy: privacyPolicy,
      },
      links: {
        websiteUrl,
        youtubeUrl,
        twitterUrl,
        discordUrl,
        telegramUrl,
        instagramUrl,
        supportWebsiteUrl,
        supportEmail,
      },
    };
  }

  private getTargetStage(
    gameStatus: GAME_STATUS
  ): 'review' | 'draft' | 'release' | 'readyForRelease' {
    switch (gameStatus) {
      case GAME_STATUS.REVIEW:
      case GAME_STATUS.REJECTED: {
        return 'review';
      }
      case GAME_STATUS.DRAFT:
      case GAME_STATUS.CHANGES_REQUESTED: {
        return 'draft';
      }
      case GAME_STATUS.READY_FOR_PUBLISH:
      case GAME_STATUS.PUBLISHING: {
        return 'readyForRelease';
      }
      default: {
        return 'release';
      }
    }
  }

  getEnumKey<T extends object>(enumerable: T, value: T[keyof T]): keyof T {
    return Object.keys(enumerable).find(
      (key) => enumerable[key as keyof T] === value
    ) as keyof T;
  }

  getEnumValue<T extends object>(enumerable: T, key: keyof T): T[keyof T] {
    return enumerable[key];
  }
}
