import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
  NonNullableFormBuilder,
} from '@angular/forms';
import { Validators as EditorValidators } from 'ngx-editor';
import { ActivatedRoute, Router } from '@angular/router';
import { BodyBackgroundSetterComponent } from '../../shared/components/body-background-setter/body-background-setter.component';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import {
  switchMap,
  of,
  Observable,
  tap,
  map,
  catchError,
  finalize,
} from 'rxjs';
import { GameData } from '../../tools/interfaces/response.interfaces';
import { AsyncPipe, NgIf, NgStyle } from '@angular/common';
import {
  GAME_CHAINS,
  GAME_GENRES,
  GAME_STAGE,
  GAME_STATUS,
  GAME_TYPE,
  RELEASE_STAGE,
} from '../../tools/constants/game.constants';
import {
  DISCORD_LINK,
  INSTAGRAM_LINK,
  TELEGRAM_LINK,
  TEXT,
  TWITTER_LINK,
  WEBSITE,
  YOUTUBE_LINK,
} from '../../tools/constants/pattern.constants';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService } from 'primeng/dynamicdialog';
import { DistributionAgreementDialogComponent } from '../../shared/modals/distribution-agreement-dialog/distribution-agreement-dialog.component';
import { GameUploadService } from '../../shared/services/games/game-upload.service';
import { GameRequestsService } from '../../shared/services/games/game-requests.service';
import { GameDraftHeaderComponent } from './game-draft-header/game-draft-header.component';
import { GameSocialLinksSubformComponent } from './game-social-links-subform/game-social-links-subform.component';
import { GameLegalLinksSubformComponent } from './game-legal-links-subform/game-legal-links-subform.component';
import { GameBasicInfoSubformComponent } from './game-basic-info-subform/game-basic-info-subform.component';
import { GameAccessSubformComponent } from './game-access-subform/game-access-subform.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GamesService } from '../../shared/services/games/games.service';
import { NotificationService } from '../../shared/services/notification.service';

@Component({
  selector: 'app-game-draft-page',
  templateUrl: './game-draft-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [],
  imports: [
    NgStyle,
    NgIf,
    AsyncPipe,
    FormsModule,
    ReactiveFormsModule,
    BodyBackgroundSetterComponent,
    TabViewModule,
    ButtonModule,
    CheckboxModule,
    GameDraftHeaderComponent,
    GameSocialLinksSubformComponent,
    GameLegalLinksSubformComponent,
    GameBasicInfoSubformComponent,
    GameAccessSubformComponent,
  ],
})
export class GameDraftPageComponent {
  public readonly GAME_STATUS: typeof GAME_STATUS = GAME_STATUS;
  public activeTabIndex: number = 0;

  readonly assetsSubform: FormGroup = this.fb.group({
    shortDescription: [
      '',
      [
        Validators.required,
        Validators.pattern(TEXT),
        Validators.minLength(50),
        Validators.maxLength(200),
      ],
    ],
    fullDescription: [
      '',
      [
        Validators.pattern(TEXT),
        EditorValidators.minLength(150),
        EditorValidators.maxLength(3500),
        EditorValidators.required(),
      ],
    ],
    genres: [
      [] as GAME_GENRES[],
      [Validators.required, Validators.maxLength(3)],
    ],
    chains: [[] as GAME_CHAINS[]],
    releaseDate: [null as unknown as Date | null],
    releaseStage: ['', [Validators.required]],
    imageUris: [[] as string[], [Validators.required]],
    bannerImageUri: ['', [Validators.required]],
    backgroundImageUri: ['', [Validators.required]],
    iconUri: ['', [Validators.required]],
  });

  readonly installationSubform: FormGroup = this.fb.group({
    archive: [null as File | null, [Validators.required]],
    executablePath: ['', [Validators.required]],
  });

  readonly externalPlayLinkSubform: FormGroup = this.fb.group({
    url: ['', [Validators.required, Validators.pattern(WEBSITE)]],
  });

  readonly legalLinksSubform: FormGroup = this.fb.group({
    terms: ['', [Validators.required]],
    license: ['', []],
    policy: ['', [Validators.required]],
  });

  readonly socialLinksSubform: FormGroup = this.fb.group({
    websiteUrl: ['', [Validators.required]],
    youtubeUrl: ['', [Validators.pattern(YOUTUBE_LINK)]],
    twitterUrl: ['', [Validators.pattern(TWITTER_LINK)]],
    discordUrl: ['', [Validators.pattern(DISCORD_LINK)]],
    telegramUrl: ['', [Validators.pattern(TELEGRAM_LINK)]],
    instagramUrl: ['', [Validators.pattern(INSTAGRAM_LINK)]],
    supportWebsiteUrl: ['', [Validators.pattern(WEBSITE)]],
    supportEmail: ['', [Validators.email]],
    gameDownloadLink: ['', Validators.pattern(WEBSITE)],
  });

  readonly gameDataForm = this.fb.group({
    basicInfo: this.assetsSubform,
    legal: this.legalLinksSubform,
    social: this.socialLinksSubform,
    sourceLink: this.externalPlayLinkSubform,
    userConsent: [false, [Validators.requiredTrue]],
  });

  constructor(
    private fb: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private gameUploadService: GameUploadService,
    private gameRequestsService: GameRequestsService,
    private gamesService: GamesService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    // TODO: move http methods into game-requests service
    private http: HttpClient
  ) {}

  gameData$!: Observable<GameData>;
  gameManifest$!: Observable<boolean>;

  ngOnInit(): void {
    this.gameData$ = this.loadGameData().pipe(
      tap((gameData) => {
        this.gameManifest$ = this.checkGameManifest(gameData.id);
      }),
      tap((gameData) => {
        if (
          gameData.status === GAME_STATUS.DRAFT ||
          gameData.status === GAME_STATUS.CHANGES_REQUESTED
        )
          return;
        this.gameDataForm.disable();
      })
    );
  }

  // TODO: fix
  isFormDataValid(gameManifestData: boolean | null): boolean | null {
    return (
      this.assetsSubform.valid &&
      this.legalLinksSubform.valid &&
      this.socialLinksSubform.valid &&
      (this.externalPlayLinkSubform.valid ||
        this.installationSubform.valid ||
        gameManifestData) &&
      this.gameDataForm.controls['userConsent'].valid
    );
  }

  uploadGameArchive(gameId: number) {
    const gameArchive: File | null =
      this.installationSubform.controls['archive'].value;
    const relativeExePath: string =
      this.installationSubform.controls['executablePath'].value;

    if (!(gameArchive instanceof File) || !relativeExePath) return of();

    return this.gameUploadService.uploadGameArchiveByChunks(
      gameId,
      gameArchive,
      relativeExePath
    );
  }

  uploadGameWebUrl(gameId: number) {
    return this.http.put(
      `${environment.devportalApi}/api/game/${gameId}/update/web`,
      {
        sourceUrl: this.externalPlayLinkSubform.controls['url'].value,
      }
    );
  }

  onOpenDistributionAgreement(): void {
    this.dialogService.open(DistributionAgreementDialogComponent, {});
  }

  saveGameDraft(gameId: number) {
    const draftRequestBody = this.composeDraftRequestBody();

    return this.gameRequestsService.saveGameDraftRequest(
      gameId,
      draftRequestBody
    );
  }

  onSubmitGameForReview(gameId: number) {
    this.saveGameDraft(gameId)
      .pipe(
        switchMap(() => {
          if (this.externalPlayLinkSubform.valid) {
            return this.uploadGameUrl(gameId);
          } else if (this.installationSubform.valid) {
            return this.uploadGame(gameId);
          } else return of(null);
        }),
        switchMap(() => {
          return this.gameRequestsService.submitGameForReviewRequest(gameId);
        })
      )
      .subscribe({
        next: () => {
          this.router.navigateByUrl(`/app/game-reviewing/${gameId}`);
          this.notificationService.throwSuccessNotification(
            'Game was successfully sent for a review!'
          );
        },
      });
  }

  private uploadGame(gameId: number) {
    return this.http
      .patch<void>(`${environment.devportalApi}/api/game/${gameId}/update`, {
        type: GAME_TYPE.DOWNLOADABLE,
      })
      .pipe(
        switchMap(() => this.uploadGameArchive(gameId)),
        catchError((error) => {
          console.error('Error uploading game archive:', error);
          return this.cancelMultipartUpload(gameId).pipe(
            finalize(() => console.log('Multipart upload canceled'))
          );
        })
      );
  }

  private uploadGameUrl(gameId: number) {
    const { url } = this.externalPlayLinkSubform.value;

    return this.http
      .patch<void>(`${environment.devportalApi}/api/game/${gameId}/update`, {
        type: GAME_TYPE.WEB,
      })
      .pipe(
        switchMap(() => {
          return this.http.put(
            `${environment.devportalApi}/api/game/${gameId}/update/web`,
            { sourceUrl: url }
          );
        })
      );
  }

  private cancelMultipartUpload(gameId: number) {
    return this.http.post<void>(
      `${environment.devportalApi}/api/storage/private/game/${gameId}/archive/multipart-upload/cancel`,
      {}
    );
  }

  private checkGameManifest(gameId: number): Observable<boolean> {
    return this.gameRequestsService
      .getGameManifest(gameId, GAME_STAGE.DRAFT)
      .pipe(
        map(() => {
          return true;
        }),
        catchError(() => {
          return of(false);
        })
      );
  }

  private loadGameData(): Observable<GameData> {
    return this.route.paramMap.pipe(
      switchMap((params) => {
        const gameId: number = Number(params.get('game-id'));
        return this.gameRequestsService.getGameByIdRequest(gameId);
      }),
      map((gameDataResponse) => {
        return this.gamesService.parseGameDataResponse(gameDataResponse);
      }),
      tap((gameData) => {
        this.patchForm(gameData);
      })
    );
  }

  private patchForm(gameData: GameData): void {
    this.assetsSubform.patchValue(gameData.info);
    this.legalLinksSubform.patchValue(gameData.legal);
    this.socialLinksSubform.patchValue(gameData.links);
    // TODO: get actual link
    this.externalPlayLinkSubform.patchValue({ url: '' });
  }

  private composeDraftRequestBody() {
    const gameDraft = this.gameDataForm.getRawValue();
    const {
      shortDescription,
      fullDescription,
      releaseDate,
      releaseStage,
      genres,
      chains,
      bannerImageUri,
      imageUris,
      backgroundImageUri,
    } = gameDraft.basicInfo;
    const { terms, policy, license } = gameDraft.legal;
    const {
      websiteUrl,
      youtubeUrl,
      twitterUrl,
      discordUrl,
      telegramUrl,
      instagramUrl,
      supportEmail,
      supportWebsiteUrl,
    } = gameDraft.social;
    const genreKeys: keyof typeof GAME_GENRES = genres.map(
      (genre: GAME_GENRES) => {
        return this.gamesService.getEnumKey(GAME_GENRES, genre);
      }
    );
    const chainKeys: keyof typeof GAME_CHAINS = chains.map(
      (chain: GAME_CHAINS) => {
        return this.gamesService.getEnumKey(GAME_CHAINS, chain);
      }
    );
    const releaseStageKey: keyof typeof RELEASE_STAGE =
      this.gamesService.getEnumKey(RELEASE_STAGE, releaseStage);

    return {
      shortDescription: shortDescription || null,
      fullDescription: fullDescription || null,
      release: {
        date: releaseDate,
        stage: releaseStageKey || RELEASE_STAGE.TBD,
      },
      genres: genreKeys.length ? genreKeys : null,
      chains: chainKeys.length ? chainKeys : [],
      termsAndConditions: terms || null,
      privacyPolicy: policy || null,
      eula: license || null,
      websiteUrl: websiteUrl || null,
      youtubeUrl: youtubeUrl || null,
      twitterUrl: twitterUrl || null,
      discordUrl: discordUrl || null,
      telegramUrl: telegramUrl || null,
      instagramUrl: instagramUrl || null,
      supportWebsiteUrl: supportWebsiteUrl || null,
      supportEmail: supportEmail || null,
      bannerImageUrl: bannerImageUri || null,
      imageUrls: imageUris,
      backgroundImageUrl: backgroundImageUri || null,
    };
  }
}
