<div
  [ngStyle]="{ padding: '100px 0', 'min-height': 'calc(100vh - 3.5rem)' }"
  class="relative"
  *ngIf="gameData$ | async as gameData"
>
  <app-game-draft-header
    [gameAssets]="assetsSubform.getRawValue()"
    [status]="gameData.status"
    [isPagePreviewDisabled]="assetsSubform.invalid"
    [gameId]="gameData.id"
    [icon]="gameData.info.iconUri"
    [title]="gameData.info.title"
    [developer]="gameData.developer.displayName"
  ></app-game-draft-header>

  <form
    [formGroup]="gameDataForm"
    class="m-auto"
    [ngStyle]="{ 'max-width': '840px' }"
  >
    <p-tabView [(activeIndex)]="activeTabIndex">
      <p-tabPanel header="Basic info">
        <ng-template pTemplate="content">
          <app-game-basic-info-subform
            [basicInfoForm]="assetsSubform"
            [gameId]="gameData.id"
          ></app-game-basic-info-subform>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel
        header="Installation files"
        *ngIf="
          gameData.status === GAME_STATUS.DRAFT ||
          gameData.status === GAME_STATUS.CHANGES_REQUESTED
        "
      >
        <ng-template pTemplate="content">
          <app-game-access-subform
            [installationForm]="installationSubform"
            [externalLinkForm]="externalPlayLinkSubform"
            [gameId]="gameData.id"
            [filesPreviouslyUploaded]="gameManifest$ | async"
          ></app-game-access-subform>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="Legal">
        <ng-template pTemplate="content">
          <app-game-legal-links-subform
            [legalForm]="legalLinksSubform"
          ></app-game-legal-links-subform>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="Social & Support">
        <ng-template pTemplate="content">
          <app-game-social-links-subform
            [socialLinksForm]="socialLinksSubform"
          ></app-game-social-links-subform>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="Send to Review">
        <ng-template pTemplate="content">
          <div class="text-center mt-6">
            @if (gameData.status === GAME_STATUS.REVIEW) {
            <p class="text-white text-xl mt-0 mb-4">
              Your request is under review. We'll notify you once it's done.
            </p>
            } @else {
            <h2 class="text-white text-2xl mb-4">
              Send {{ gameData.info.title }} to GAIMIN Review
            </h2>
            <div
              class="flex gap-2 mb-4 justify-content-center align-items-center"
            >
              <p-checkbox
                [binary]="true"
                inputId="user-consent"
                formControlName="userConsent"
              />
              <p class="text-gray-400 text-md">
                By checking, you agree to our
                <a
                  class="text-primary font-normal no-underline hover:text-primary-300 cursor-pointer"
                  (click)="onOpenDistributionAgreement()"
                >
                  Distribution Agreement
                </a>
              </p>
            </div>
            <p-button
              class="p-button-primary p-button-md"
              (onClick)="onSubmitGameForReview(gameData.id)"
              [disabled]="!isFormDataValid(gameManifest$ | async)"
            >
              Send to review
            </p-button>
            }
          </div>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </form>
  @if (activeTabIndex !== 4) {
  <div
    class="p-2 bottom-0 right-0 fixed w-full"
    [ngStyle]="{
    'background-color': '#0A0118',
  }"
  >
    <div
      class="m-auto flex justify-content-end gap-2"
      [ngStyle]="{ 'max-width': '1000px' }"
    >
      @if (gameData.status === GAME_STATUS.DRAFT || gameData.status ===
      GAME_STATUS.CHANGES_REQUESTED) {
      <p-button
        severity="secondary"
        label="Save in draft"
        (onClick)="saveGameDraft(gameData.id).subscribe()"
      ></p-button>
      }

      <p-button
        label="Next step"
        (onClick)="activeTabIndex = activeTabIndex + 1"
      ></p-button>
    </div>
  </div>
  }

  <img
    class="absolute left-0 w-full rotate-180"
    [ngStyle]="{ top: '0px', 'z-index': -1 }"
    src="./assets/illustrations/background-lights.png"
    alt=""
  />

  <img
    class="absolute left-0 bottom-0 w-full"
    [ngStyle]="{ 'z-index': -1 }"
    src="./assets/illustrations/background-lights.png"
    alt=""
  />
</div>

<app-body-background-setter></app-body-background-setter>
