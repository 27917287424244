import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GAME_STATUS } from '../../../tools/constants/game.constants';
import {
  ContentWrapper,
  GameData,
  GameDataResponse,
} from '../../../tools/interfaces/response.interfaces';
import { AdminRequestsService } from './admin-requests.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private adminRequestsService: AdminRequestsService) {}

  getGamesForReview(
    statuses: GAME_STATUS[] = []
  ): Observable<ContentWrapper<GameDataResponse[]>> {
    let params = new HttpParams();
    const statusesForRequest =
      statuses.length === 0
        ? [
            GAME_STATUS.CHANGES_REQUESTED,
            GAME_STATUS.REJECTED,
            GAME_STATUS.READY_FOR_PUBLISH,
            GAME_STATUS.REVIEW,
            GAME_STATUS.PUBLISH_REQUESTED,
            GAME_STATUS.UNPUBLISH_REQUESTED,
            GAME_STATUS.PUBLISHED,
            GAME_STATUS.PUBLISHING,
          ]
        : statuses;

    // TODO: rewrite using gamesService parsing method
    statusesForRequest.forEach((status) => {
      params = params.append(
        'status',
        status.split(' ').join('_').toUpperCase()
      );
    });
    // TODO: rewrtire using actual backend pagination
    params = params.append('size', 1_000_000);

    return this.adminRequestsService.getGamesForReviewRequest(params);
  }

  onRedirect(link: string | undefined): void {
    if (!link) return;

    window.open(link, '_blank');
  }
}
