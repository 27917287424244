import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  switchMap,
  finalize,
  catchError,
  throwError,
  from,
  mergeMap,
  tap,
  toArray,
} from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LoaderService } from '../loader.service';
import { WakeLockService } from '../wake-lock.service';

@Injectable({
  providedIn: 'root',
})
export class GameUploadService {
  constructor(
    private http: HttpClient,
    private loaderService: LoaderService,
    private wakeLockService: WakeLockService
  ) {}

  uploadGameArchiveByChunks(
    gameId: number,
    gameArchive: File,
    relativeGameExePath: string
  ) {
    const { chunks: archiveChunks, chunksQuantity } =
      this.getFileChunks(gameArchive);
    const parts: { eTag: string | null; partNumber: number }[] = new Array(
      chunksQuantity
    ).fill(null);

    return this.initializeMultipartUploadRequest(gameId, chunksQuantity).pipe(
      switchMap(({ urls }) => {
        this.wakeLockService.requestWakeLock();
        this.loaderService.updateLoaderMessage(
          'Preparing your game to upload...',
          0
        );

        return this.uploadChunks(
          urls,
          archiveChunks,
          parts,
          chunksQuantity
        ).pipe(
          switchMap(() =>
            this.completeMultipartUpload(gameId, parts, relativeGameExePath)
          )
        );
      }),
      finalize(() => {
        this.loaderService.setMessage('Upload completed!');
        this.loaderService.setPercentage(100);
      }),
      catchError((error) => {
        this.loaderService.setMessage('Upload failed.');
        this.loaderService.setPercentage(0);
        this.wakeLockService.releaseWakeLock();
        return throwError(() => error);
      })
    );
  }

  private initializeMultipartUploadRequest(
    gameId: number,
    chunksQuantity: number
  ) {
    return this.http.post<{ uploadId: string; urls: { url: string }[] }>(
      `${environment.devportalApi}/api/storage/private/game/${gameId}/archive/multipart-upload/init`,
      {
        partCount: chunksQuantity,
      }
    );
  }

  private uploadChunks(
    urls: { url: string }[],
    archiveChunks: Blob[],
    parts: { eTag: string | null; partNumber: number }[],
    chunksQuantity: number
  ) {
    let loadedChunksCount: number = 0;

    return from(urls).pipe(
      mergeMap(
        (chunkUploadUrl, index) =>
          this.uploadChunkRequest(
            chunkUploadUrl.url,
            archiveChunks[index],
            index
          ).pipe(
            tap((response: HttpResponse<any>) => {
              parts[index] = {
                eTag: response.headers.get('ETag'),
                partNumber: index + 1,
              };

              loadedChunksCount++;
              this.loaderService.updateLoaderMessage(
                `Loading chunks: ${loadedChunksCount} out of ${chunksQuantity}`,
                (loadedChunksCount / chunksQuantity) * 100
              );
            })
          ),
        3
      ),
      toArray()
    );
  }

  private completeMultipartUpload(
    gameId: number,
    parts: { eTag: string | null; partNumber: number }[],
    relativeExePath: string
  ) {
    const eTags = parts.map((part) => part.eTag);
    const manifest = {
      executable: relativeExePath,
      includePlayerToken: false,
      runFlags: [],
    };
    this.wakeLockService.releaseWakeLock();
    return this.http.post(
      `${environment.devportalApi}/api/storage/private/game/${gameId}/archive/multipart-upload/complete`,
      { eTags, manifest }
    );
  }

  private uploadChunkRequest(url: string, chunk: Blob, index: number) {
    return this.http.put(url, chunk, { observe: 'response' }).pipe(
      catchError((error) => {
        this.loaderService.setMessage(`Failed to upload chunk ${index + 1}`);
        return throwError(() => error);
      })
    );
  }

  private getFileChunks(file: File): {
    chunks: Blob[];
    chunksQuantity: number;
  } {
    const chunkSize: number = 50 * 1024 * 1024; // 50mb
    const chunks: Blob[] = [];
    const chunksQuantity: number = Math.ceil(file.size / chunkSize);

    for (let i: number = 0; i < chunksQuantity; i++) {
      const start: number = i * chunkSize;
      const end: number = Math.min(start + chunkSize, file.size);
      const chunk: Blob = file.slice(start, end);
      chunks.push(chunk);
    }

    return {
      chunks,
      chunksQuantity,
    };
  }
}
