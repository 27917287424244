import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NOTIFICATION } from '../../tools/constants/notification.constants';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  isOnline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    navigator.onLine
  );

  constructor(private notificationService: NotificationService) {
    this.initializeNetworkStatus();
  }

  get isOnline(): boolean {
    return this.isOnline$.value;
  }

  get onlineStatus$(): Observable<boolean> {
    return this.isOnline$.asObservable();
  }

  private initializeNetworkStatus(): void {
    const online$: Observable<Event> = fromEvent(window, 'online');
    const offline$: Observable<Event> = fromEvent(window, 'offline');

    merge(online$, offline$)
      .pipe(map(() => navigator.onLine))
      .subscribe((status: boolean) => {
        this.isOnline$.next(status);

        this.notifyNetworkStatus(status);
      });
  }

  private notifyNetworkStatus(networkStatus: boolean): void {
    networkStatus
      ? this.notificationService.throwSuccessNotification(
          'Connection restored!'
        )
      : this.notificationService.throwErrorNotification(
          'There are some problems with internet connection'
        );
  }
}
